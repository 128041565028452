import isObject from 'lodash/isObject';

export const createOrderUrl = offer =>
  isObject(offer) ? `/order/${offer.id}` : `/order/${offer}`;

export const createFirstOrderUrl = offer => `${createOrderUrl(offer)}?first`;

export const resolveId = slug => slug;

export const listOrders = () => `/orders/`;
